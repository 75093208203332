<template>
  <div class="wrapper">
    <div>
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast
            :key="info.message"
            :show="true"
            :header="info.header"
            :color="info.color"
          >
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
    </div>
    <div>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader> <strong> Vehicle </strong> List </CCardHeader>
            <CCardBody>
              <CDataTable
                :items="computedItems"
                :fields="fields"
                column-filter
                items-per-page-select
                :items-per-page="50"
                hover
                sorter
                pagination
                table-filter
                cleaner
                :loading="loading"
              >

                <template #show_index="{ index }">
                  <td class="py-2">
                    {{ index + 1 }}
                  </td>
                </template>

                <template #latest_stock_status="{ item }">
                  <td>
                    <CBadge :color="getBadge(getLatesStockStatusName(item))">{{
                      getLatesStockStatusName(item)
                    }}</CBadge>
                  </td>
                </template>
                <template #status_name="{ item }">
                  <td>
                    <CBadge :color="getBadge(item.stockStatus.name)">{{
                      item.stockStatus.name
                    }}</CBadge>
                  </td>
                </template>
                <template #show_image="{ item }">
                  <td class="py-2">
                    <CImg
                      thumbnail
                      :src="getImage(item)"
                      height="70"
                      width="70"
                    />
                  </td>
                </template>
                <template #show_details="{ item, index }">
                  <td class="py-2">
                    <CButton
                      color="primary"
                      variant="outline"
                      square
                      size="sm"
                      @click="toggleDetails(item, index)"
                    >
                      {{ Boolean(item._toggled) ? "Hide" : "Show" }}
                    </CButton>
                  </td>
                </template>
                <template #details="{ item }">
                  <CCollapse
                    :show="Boolean(item._toggled)"
                    :duration="collapseDuration"
                  >
                    <CCardBody>
                      <p class="text-muted">Stock No: {{ item.stockNo }}</p>
                      <p class="text-muted">
                        Status: {{ getLatesStockStatusName(item) }}
                      </p>
                      <p class="text-muted">Brand: {{ item.brandName }}</p>
                      <p class="text-muted">Model: {{ item.modelName }}</p>
                      <!-- <p class="text-muted">Price: {{ item.modelName }}</p> -->
                      <CButton
                        size="sm"
                        color="primary"
                        class="ml-1"
                        @click="showDetails(item)"
                      >
                        Details
                      </CButton>
                      <!-- <CButton
                        size="sm"
                        color="danger"
                        class="ml-1"
                        @click="showDeleteConfirmation(item)"
                      >
                        Delete
                      </CButton> -->
                    </CCardBody>
                  </CCollapse>
                </template>
              </CDataTable>
            </CCardBody>
            <CCardFooter>
            </CCardFooter>
          </CCard>
         
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import StockApi from "../../lib/stockApi";

const items = [];
const fields = [
{
    key: "show_index",
    label: "#",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
  { key: "stockNo" },
  {
    key: "latest_stock_status",
    _style: "min-width:100px;",
    label: "Status",
  },
  { key: "brandName" },
  { key: "modelName" },
  { key: "price" },
  {
    key: "show_image",
    label: "Image",
  },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

export default {
  name: "StockList",
  data() {
    return {
      loading: true,
      items: items.map((item, id) => {
        return { ...item, id };
      }),
      infoList: [],
      fields,
      details: [],
      collapseDuration: 0,
      api: new StockApi(),
      warningModal: false,
      itemToDelete: {},
    };
  },
  mounted() {
    var self = this;
    self.refreshTable();
  },
  computed: {
    isAllowDelete() {
      if (auth.getRole() == "super") return true;
      return false;
    },
    computedItems() {
      return this.items.map((item) => {
        return {
          ...item,
          brandName: this.getBrandName(item),
          modelName: this.getModelName(item),
          price: this.getPricing(item),
        };
      });
    },
    computedExportItemForOperatorRole() {
      return this.computedItems.map((item) => {
        return {
          orderNo: item.orderNo,
          providerOrderNo: item.providerOrderNo,
          creatorFullName: item.createdBy.fullName,
          creatorPhone: item.createdBy.phone,
          agentFullName: item.agent.fullName,
          agentPhone: item.agent.phone,
          companyName: item.companyName,
          companyRegNo: item.companyRegNo,
          projectName: item.projectName,
          customerName: item.customerName,
          myKadOrPassport: item.myKadOrPassport,
          residentialType: item.residentialType,
          residentialName: item.residentialName,
          appointmentDate: item.appointmentDate,
          fullAddress: this.getFullAddress(item.fullAddress),
          postcode: item.postcode,
          city: item.city,
          state: item.state,
          phone: item.phone,
          email: item.email,
          submittedDate: item.submittedDate,
          serviceProviderName: item.serviceProviderName,
          packageName: item.packageName,
          internalStatus: item.internalStatus,
          providerStatus: item.providerStatus,
          isPaid: item.isPaid,
        };
      });
    },
    computedExportItem() {
      return this.computedItems.map((item) => {
        return {
          //...item,
          orderNo: item.orderNo,
          providerOrderNo: item.providerOrderNo,
          creatorFullName: item.createdBy.fullName,
          creatorPhone: item.createdBy.phone,
          agentFullName: item.agent.fullName,
          agentPhone: item.agent.phone,
          companyName: item.companyName,
          companyRegNo: item.companyRegNo,
          projectName: item.projectName,
          customerName: item.customerName,
          myKadOrPassport: item.myKadOrPassport,
          residentialType: item.residentialType,
          residentialName: item.residentialName,
          appointmentDate: item.appointmentDate,
          fullAddress: this.getFullAddress(item.fullAddress),
          // address1: item.address1,
          // address2: item.address2,
          // address3: item.address3,
          postcode: item.postcode,
          city: item.city,
          state: item.state,
          phone: item.phone,
          email: item.email,
          submittedDate: item.submittedDate,
          serviceProviderName: item.serviceProviderName,
          packageName: item.packageName,
          internalStatus: item.internalStatus,
          providerStatus: item.providerStatus,
          isPaid: item.isPaid,
          agentCommision: item.agentCommision,
        };
      });
    },
    csvContent() {
      var role = auth.getRole();
      if (role == "operator") {
        this.computedExportItemForOperatorRole.unshift({
          orderNo: "Order No",
          providerOrderNo: "Official Order No",
          creatorFullName: "Creator Name",
          creatorPhone: "Creator",
          agentFullName: "Agent Name",
          agentPhone: "Agent Phone",
          companyName: "Company Name",
          companyRegNo: "Company Reg",
          projectName: "Project Name",
          customerName: "Customer Name",
          myKadOrPassport: "MyKad",
          residentialType: "Residential Type",
          residentialName: "Residential Name",
          appointmentDate: "Appointment Date",
          fullAddress: "Address",
          // address1: "Address1",
          // address2: "Address2",
          // address3: "Address3",
          postcode: "Postcode",
          city: "City",
          state: "State",
          phone: "Phone",
          email: "Email",
          submittedDate: "Submitted Date",
          serviceProviderName: "Services",
          packageName: "Package",
          internalStatus: "Internal Status",
          providerStatus: "Provider Status",
          isPaid: "Is Paid",
        });

        return this.computedExportItemForOperatorRole
          .map((item) => Object.values(item).join(","))
          .join("\n");
      } else {
        this.computedExportItem.unshift({
          orderNo: "Order No",
          providerOrderNo: "Official Order No",
          creatorFullName: "Creator Name",
          creatorPhone: "Creator",
          agentFullName: "Agent Name",
          agentPhone: "Agent Phone",
          companyName: "Company Name",
          companyRegNo: "Company Reg",
          projectName: "Project Name",
          customerName: "Customer Name",
          myKadOrPassport: "MyKad",
          residentialType: "Residential Type",
          residentialName: "Residential Name",
          appointmentDate: "Appointment Date",
          fullAddress: "Address",
          // address1: "Address1",
          // address2: "Address2",
          // address3: "Address3",
          postcode: "Postcode",
          city: "City",
          state: "State",
          phone: "Phone",
          email: "Email",
          submittedDate: "Submitted Date",
          serviceProviderName: "Services",
          packageName: "Package",
          internalStatus: "Internal Status",
          providerStatus: "Provider Status",
          isPaid: "Is Paid",
          agentCommision: "Commision",
        });

        return this.computedExportItem
          .map((item) => Object.values(item).join(","))
          .join("\n");
      }
    },
    csvCode() {
      return (
        "data:text/csv;charset=utf-8," + encodeURIComponent(this.csvContent)
      );
    },
    computeStartDate() {
      return moment(this.startDate).format("YYYY-MM-DDTHH:mm");
    },
    computeEndDate() {
      return moment(this.endDate).format("YYYY-MM-DDTHH:mm");
    },
  },

  methods: {
    removeTrailingSlash(str) {
      if (str.endsWith("/")) {
        return str.slice(0, -1);
      }
      return str;
    },

    getImage(item) {
      // var url = apiUrl + "incident/image_display/" + item.imagepath;
      // console.log(item);
      // if (item.vehicle == null) return "";
      // if (item.vehicle.vehiclePhotoList == null) return "";
      // if (item.vehicle.vehiclePhotoList.length == 0) return "";
      var url = "";

      url = this.removeTrailingSlash(apiUrl) + item.vehicle.defaultImageUrl;// "Documents/File/" + item.vehicle.vehiclePhotoList[0].documentId;

      return url;
    },
    getPricing(item) {
      if (item.pricing == null) return "N/A";
      if (item.pricing.recommendedSalePrice == 0) return "N/A";
      return new Intl.NumberFormat("ms-MY", {
        style: "currency",
        currency: "MYR",
      }).format(item.pricing.recommendedSalePrice);
    },
    showDetails(item) {
      var self = this;
      self.$router.push({
        path: `/agents/vehicle/${item.id}`,
      });


    },
    getBrandName(item) {
      if (item.vehicle == null) return "N/A";
      if (item.vehicle.brand == null) return "N/A";
      return item.vehicle.brand.name;
    },
    getModelName(item) {
      if (item.vehicle == null) return "N/A";
      if (item.vehicle.model == null) return "N/A";
      return item.vehicle.model.name;
    },
    getLatesStockStatusName(item) {
      if (item.latestStockStatus == null) return "N/A";
      if (item.latestStockStatus.stockStatus == null) return "N/A";
      return item.latestStockStatus.stockStatus.name;
    },
    getBadge(status) {
      return status === "Draft"
        ? "primary"
        : status === "Registered"
        ? "secondary"
        : status === "Shipped"
        ? "warning"
        : status === "ArriveAtPort"
        ? "success"
        : status === "ShowRoom"
        ? "info"
        : status === "Available"
        ? "danger"
        : status === "Booked"
        ? "light"
        : status === "Cancelled"
        ? "dark"
        : "";
    },
    download() {},
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    toggleDetails(item, index) {
      this.$set(item, "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    refreshTable() {
      var self = this;
      self.loading = true;
      self.api
        .getForSaleList()
        .then((response) => {
          self.items = response.result;
          console.log(self.items);
          self.loading = false;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
          self.loading = false;
        });
    },
    onEdit(item) {
      var self = this;
      self.$router.push({
        path: `/admins/Stock/${item.id}`,
      });
    },
    onDeleteConfirmation(status, evt, accept) {
      var self = this;
      if (accept) {
        this.api
          .delete(self.itemToDelete.id)
          .then((response) => {
            self.refreshTable();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
      self.itemToDelete = {};
    },
    showDeleteConfirmation(item) {
      var self = this;
      self.itemToDelete = item;
      self.warningModal = true;
    },
    addNew() {
      this.$router.push({ path: "/admins/Stock" });
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
  },
};
</script>

